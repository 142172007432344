import React from "react";

const TermsConditions = () => {
  return (
    <div className="p-4">
      <div className="flex justify-start">
        <p className="text-xl md:text-2xl font-audiowide gradient-text">
          <span className="text-xl md:text-2xl font-audiowide  gradient-text ">
            {" "}
            Terms and Conditions
          </span>
        </p>
      </div>
      <div className="bg-light p-4 md:p-8 mt-5 md:mt-4 rounded-xl">
        <div className="bg-tab p-4 md:p-6 rounded-sm">
          <div className="mb-6">
            <p className="audio text-xs lg:text-sm font-audiowide">
              I hereby solemnly and sincerely declared:
            </p>
          </div>
          <div className="flex flex-col gap-4">
            <p className="text-xs lg:text-sm font-audiowide">
              <span className="gradient-text mr-1">{">>"}</span>That all my
              documents like Mark-sheets, Transcripts, Certificates, Bank
              Statements, Income- tax return papers etc submitted/used for my
              student application are genuine
            </p>
            <p className="text-xs lg:text-sm font-audiowide">
              <span className="gradient-text mr-1">{">>"}</span>I state under
              the oath that all the documents are genuine and have been procured
              from the sources directly by me from respective boards, college,
              institutes, school or university.
            </p>
            <p className="text-xs lg:text-sm font-audiowide">
              <span className="gradient-text mr-1">{">>"}</span> I am solemnly
              responsible and ready to face the consequences for my incorrect or
              fraudulent information, if found any. I am answerable to any of
              the queries regarding all the documents submitted by me.
            </p>
            <p className="text-xs lg:text-sm font-audiowide">
              <span className="gradient-text mr-1">{">>"}</span>I have
              understood all the information provided to me by the
              representative of the school, college or university abroad and
              have verified it by myself through the official website of the
              respective institute, country and its laws.
            </p>
            <p className="text-xs lg:text-sm font-audiowide">
              <span className="gradient-text mr-1">{">>"}</span> I understand
              that the overseas institute’s representative is only a facilitator
              who facilitates/guides the admission procedure.
            </p>
            <p className="text-xs lg:text-sm font-audiowide">
              <span className="gradient-text mr-1">{">>"}</span> I have
              understood the refund policy of the tuition fees by the institute
              I am applying to. I agree on the refund policy terms and I
              understand to make all the school/college/institute’s tuition fees
              transferred directly to the overseas institution or the
              organization I am applying to.
            </p>
            <p className="text-xs lg:text-sm font-audiowide">
              <span className="gradient-text mr-1">{">>"}</span>I understand
              that the representative is not responsible for the refund of any
              tuition fees paid to the overseas school, college or institute I
              have applied to. I also agree & understand the service charges
              against my admission/application process, these charges are
              non-refundable as it is against the service being provided to me
              for my admission process for my selected course &
              university/school/institute.
            </p>
            <p className="text-xs lg:text-sm font-audiowide">
              <span className="gradient-text mr-1">{">>"}</span>The course,
              institute and the country selection is purely my responsibility
              and after understanding everything I myself have taken the
              decision to apply for the admission at the institute and have
              submitted the visa application for the same to the country’s
              consulate, high commission or embassy.
            </p>
            <p className="text-xs lg:text-sm font-audiowide">
              <span className="gradient-text mr-1">{">>"}</span>I understand
              that there will not be any Refund of Tuition fee once the Student
              Visa is granted to me and I will not change the institute till my
              course completion without the written permission from the college,
              university or institute I have applied to after landing in the
              selected country I have applied for.
            </p>
            <p className="text-xs lg:text-sm font-audiowide">
              <span className="gradient-text mr-1">{">>"}</span>I agree &
              confirm that my representative helps me with only my admission
              process & holds no responsibility for getting me a work at foreign
              country /stay back at foreign country/lodging at foreign country/
              travel etc once the visa has been granted. I also confirm that my
              representative does not hold any responsibility for my acts after
              I get Visa/ I reach the chosen country.
            </p>
            <p className="text-xs lg:text-sm font-audiowide">
              <span className="gradient-text mr-1">{">>"}</span>
              <u>OPTIONAL :</u> I fully authorize my representative to use my
              visa details and photograph if they want to do the
              marketing/advertisement and state that they will not need to take
              any further permission from me or from my family or outsources.
            </p>
          </div>

          <div className="mt-6">
            <p className="text-[10px] lg:text-xs font-audiowide ">
              <u>
                THE ABOVE DEFINITIONS ARE ALSO APPLICABLE FOR THE BELOW DOMAINS
                THAT ARE OWNED TOGETHER:
              </u>
            </p>
          </div>
          <div className="w-full">
            <p>
              <span className="gradient-text text-[11.4px] lg:text-sm font-audiowide">
                WWW.APPLY4GRADUATION.COM
              </span>
            </p>
            <p>
              <span className="gradient-text text-[11.4px] lg:text-sm font-audiowide">
                WWW.APPLYUNINOW.COM
              </span>
            </p>
            <p>
              <span className="gradient-text text-[11.4px] lg:text-sm font-audiowide">
                WWW.ADMITSCONNECT.COM
              </span>
            </p>
            <p>
              <span className="gradient-text text-[11.4px] lg:text-sm font-audiowide">
                WWW.UNIFEATURES.COM
              </span>
            </p>
            <p>
              <span className="gradient-text text-[11.4px] lg:text-sm font-audiowide break-word">
                WWW.INTERNATIONALSTUDENTVISAS.COM
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;
