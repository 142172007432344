import React, { createContext } from "react";
import { environment } from "../environments/environment";
export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const BASE_URL = environment.API_BASE_URL;
  let token = localStorage.getItem("token");

  return (
    <AppContext.Provider
      value={{
        BASE_URL,
        token,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
