import { Layout } from "./Layout/Layout";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorBoundary } from './components/ErrorBoundry';


const OurFallbackComponent = ({ error, componentStack, resetErrorBoundary }) => (
  <div>
    <h1>
      An error occurred:
      {error.message}
    </h1>
    <h1>
      An error occurred:
      {componentStack}
    </h1>
    <button onClick={resetErrorBoundary}>Try again</button>
  </div>
);

function App() {

  return (
    <div>
      <ErrorBoundary
        FallbackComponent={OurFallbackComponent}
      >
        <Layout />
        <ToastContainer />
      </ErrorBoundary>

    </div>
  );
}

export default App;
