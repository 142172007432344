import React from "react";
import ScubeLogo from "../assets/scubelogo/SCUBE.png";
import "../styles/Footer.css";
export const Footer = () => {
  return (
    <div className="relative text-xs md:text-sm flex flex-col justify-center md:justify-between p-2 md:p-4  text-center text-white sm:flex-row bg-darkblue footer ">
      <div>
        <p className="text-white">
          Copyright © {new Date().getFullYear()} ApplyUniNow. All Rights Reserved.
        </p>
      </div>
      <div className="mt-2 hidden md:block text-white md:mt-0">
        <a href="https://scube.me" target="_blank">
          <p className="text-white">
            Made with{" "}
            <svg
              className="love align-top inline-block mr-1.5"
              width="18"
              height="16"
              viewBox="0 0 28 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.8"
                d="M7.53299 0C3.37264 0 0 3.33558 0 7.45021C0 9.42613 0.793651 11.3211 2.20636 12.7183L13.34 23.7297C13.7045 24.0901 14.2955 24.0901 14.66 23.7297L25.7936 12.7183C27.2063 11.3211 28 9.42613 28 7.45021C28 3.33558 24.6273 0 20.4671 0C18.4691 0 16.5531 0.784931 15.1404 2.18212L14 3.30995L12.8596 2.18212C11.4469 0.784931 9.53086 0 7.53299 0Z"
                fill="#CF6470"
              />
            </svg>
            by
            <svg
              className="hidden pl-2 sm:inline-block"
              width="85"
              height="19"
              viewBox="0 0 111 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.9278 0.767964C8.35875 0.540419 8.18758 0 5.53447 0C3.08104 0 0.228225 1.50748 0.228225 4.92066C0.228225 8.2485 3.90836 10.012 5.96239 10.8084C7.70261 11.491 8.55845 12.9416 8.55845 14.5913C8.55845 16.6961 7.47438 17.8338 5.30624 17.8338C3.39485 17.8338 1.71169 16.241 1.14113 13.6527L0 13.738C0.285282 15.8997 0.484979 17.265 0.542035 18.7156C1.02701 18.7156 1.28377 18.5734 1.85433 18.5734C2.53901 18.5734 3.10957 19 5.90533 19C9.15754 19 11.611 17.265 11.611 13.482C11.611 10.0404 8.78668 8.53293 6.39031 7.67964C5.19213 7.25299 2.9384 5.83084 2.9384 4.01048C2.9384 2.41767 3.62308 1.10928 5.50594 1.10928C7.93083 1.10928 8.87226 3.35629 9.38577 5.23353L10.5269 5.09132C10.2701 3.69761 10.0419 2.27545 9.9278 0.767964Z"
                fill="#ffffff"
              />
              <path
                d="M29.7152 19C32.7392 19 35.1926 17.6063 37.1325 15.0464L36.2767 14.3638C34.08 16.8383 31.9975 17.7485 29.6867 17.7485C25.7213 17.7485 23.8099 13.2545 23.8099 9.21557C23.8099 3.58383 26.9765 1.16617 29.7437 1.16617C33.3953 1.16617 34.6221 3.58383 35.5064 6.28593L36.5905 6.05838C36.3052 4.49401 36.077 2.9012 35.9629 1.2515C34.5079 1.2515 33.538 0 29.7437 0C24.466 0 20.1012 4.15269 20.1012 9.75599C20.1012 14.3353 23.2108 19 29.7152 19Z"
                fill="#ffffff"
              />
              <path
                d="M59.5561 2.53144V10.8084C59.5561 13.8802 59.0141 17.8338 54.8204 17.8338C53.7649 17.8338 52.7379 17.2365 51.9106 16.4401C50.5983 15.1033 50.5983 12.9132 50.5983 11.0359V2.53144C50.5983 1.73503 50.9691 1.27994 52.1388 1.27994H52.5382C52.6808 1.27994 52.7664 1.19461 52.7664 1.05239V0.369761L52.7094 0.341318C52.7094 0.341318 49.8565 0.369761 48.8581 0.369761C47.7169 0.369761 44.9497 0.341318 44.9497 0.341318L44.8926 0.369761V1.05239C44.8926 1.19461 45.0068 1.27994 45.1494 1.27994H45.5488C46.6614 1.27994 47.0893 1.62126 47.0893 2.53144V11.9745C47.0893 17.3787 51.4541 19 54.2214 19C60.5261 19 61.6387 15.3877 61.6387 10.3248V2.53144C61.6387 1.73503 62.0381 1.27994 63.2077 1.27994H63.6071C63.7498 1.27994 63.8354 1.19461 63.8354 1.05239V0.369761L63.7783 0.341318C63.7783 0.341318 61.6101 0.369761 60.5831 0.369761C59.4705 0.369761 57.388 0.341318 57.388 0.341318L57.3309 0.369761V1.05239C57.3309 1.19461 57.445 1.27994 57.5877 1.27994H57.9871C59.0997 1.27994 59.5561 1.62126 59.5561 2.53144Z"
                fill="#ffffff"
              />
              <path
                d="M77.5902 2.73054C77.5902 1.90569 77.7328 1.50749 79.5871 1.50749C80.8994 1.50749 82.7823 2.01946 82.7823 4.80689C82.7823 7.08233 81.2132 8.02096 79.1877 8.02096H77.5902V2.73054ZM77.5902 9.21557H79.6157C82.5541 9.21557 84.1231 10.9506 84.1231 13.3967C84.1231 15.1033 83.2387 17.5778 79.473 17.5778C77.7613 17.5778 77.5902 17.1512 77.5902 16.3263V9.21557ZM71.913 18.1183V18.6871L71.9416 18.7156H80.0151C85.8633 18.7156 87.7462 16.1841 87.7462 13.7096C87.7462 11.2066 86.491 9.3009 83.467 8.50449V8.4476C85.0075 7.76497 86.2057 6.2006 86.2057 4.75C86.2057 2.78743 85.1216 0.312875 79.3874 0.312875C77.961 0.312875 76.5346 0.369761 75.6788 0.369761C74.8229 0.369761 71.9701 0.312875 71.9701 0.312875L71.913 0.369761V0.995509C71.913 1.19461 71.9986 1.27994 72.3124 1.27994H73.2539C73.9386 1.27994 74.1097 1.59281 74.1097 2.44611V16.6108C74.1097 17.521 73.91 17.7769 73.2539 17.7769H72.4266C71.9986 17.7769 71.913 17.9192 71.913 18.1183Z"
                fill="#ffffff"
              />
              <path
                d="M96.308 18.7156H110.23C110.429 17.1796 110.715 15.4162 111 13.9087L109.773 13.6243C109.288 15.729 108.261 17.4072 106.692 17.4072H102.327C102.071 17.4072 101.928 17.2365 101.928 16.5823V9.52844H105.152C105.865 9.52844 105.979 10.3817 106.15 11.5479C106.15 11.6617 106.321 11.8323 106.607 11.8323H107.206L107.377 11.5763C107.32 11.0928 107.177 9.95509 107.177 8.93114C107.177 7.87874 107.32 6.74102 107.377 6.28593L107.206 6.0015H106.607C106.321 6.0015 106.15 6.2006 106.15 6.31437C105.979 7.4521 105.865 8.33383 105.152 8.33383H101.928V2.47455C101.928 1.87725 102.071 1.6497 102.327 1.6497H106.036C107.947 1.6497 108.661 3.75449 109.06 5.20509L110.201 4.9491C109.916 3.4985 109.716 1.99102 109.602 0.341318H96.308L96.2224 0.398203V1.05239C96.2224 1.19461 96.3365 1.27994 96.4506 1.27994H96.85C97.9911 1.27994 98.4191 1.62126 98.4191 2.53144V16.5254C98.4191 17.4356 97.9911 17.7769 96.85 17.7769H96.4506C96.3365 17.7769 96.2224 17.8623 96.2224 18.0329V18.6587L96.308 18.7156Z"
                fill="#ffffff"
              />
            </svg>
            <svg
              className="inline-block pl-2 sm:hidden"
              width="58"
              height="11"
              viewBox="0 0 58 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.14454 1.13465C4.33147 1.01674 4.24277 0.736694 2.86793 0.736694C1.59658 0.736694 0.118265 1.51787 0.118265 3.28656C0.118265 5.01103 2.02529 5.92485 3.08968 6.33755C3.99145 6.69128 4.43495 7.44298 4.43495 8.29784C4.43495 9.38854 3.87319 9.9781 2.74967 9.9781C1.7592 9.9781 0.88699 9.15271 0.591326 7.81145L0 7.85567C0.147832 8.97584 0.251314 9.68332 0.28088 10.435C0.532194 10.435 0.665242 10.3613 0.960906 10.3613C1.3157 10.3613 1.61136 10.5824 3.06011 10.5824C4.7454 10.5824 6.01675 9.68332 6.01675 7.72302C6.01675 5.93959 4.55321 5.15842 3.31143 4.71625C2.69054 4.49516 1.52267 3.75821 1.52267 2.81491C1.52267 1.98952 1.87746 1.31152 2.85315 1.31152C4.10972 1.31152 4.59756 2.47591 4.86366 3.44869L5.45499 3.37499C5.32194 2.65278 5.20367 1.91582 5.14454 1.13465Z"
                fill="white"
              />
              <path
                d="M15.3983 10.5824C16.9653 10.5824 18.2366 9.86019 19.2419 8.53367L18.7984 8.17993C17.6601 9.46223 16.5809 9.93388 15.3835 9.93388C13.3286 9.93388 12.3382 7.60511 12.3382 5.51216C12.3382 2.59382 13.9791 1.341 15.4131 1.341C17.3053 1.341 17.941 2.59382 18.3993 3.99403L18.961 3.87612C18.8132 3.06547 18.6949 2.24008 18.6358 1.38521C17.8819 1.38521 17.3792 0.736694 15.4131 0.736694C12.6782 0.736694 10.4164 2.8886 10.4164 5.7922C10.4164 8.16519 12.0277 10.5824 15.3983 10.5824Z"
                fill="white"
              />
              <path
                d="M30.8617 2.04847V6.33755C30.8617 7.92937 30.5808 9.9781 28.4077 9.9781C27.8607 9.9781 27.3285 9.66858 26.8998 9.25589C26.2198 8.56315 26.2198 7.42824 26.2198 6.45546V2.04847C26.2198 1.63578 26.412 1.39995 27.0181 1.39995H27.225C27.299 1.39995 27.3433 1.35574 27.3433 1.28204V0.928303L27.3137 0.913564C27.3137 0.913564 25.8354 0.928303 25.318 0.928303C24.7267 0.928303 23.2927 0.913564 23.2927 0.913564L23.2632 0.928303V1.28204C23.2632 1.35574 23.3223 1.39995 23.3962 1.39995H23.6032C24.1797 1.39995 24.4015 1.57682 24.4015 2.04847V6.94185C24.4015 9.74227 26.6633 10.5824 28.0972 10.5824C31.3643 10.5824 31.9409 8.71054 31.9409 6.08698V2.04847C31.9409 1.63578 32.1478 1.39995 32.7539 1.39995H32.9609C33.0348 1.39995 33.0792 1.35574 33.0792 1.28204V0.928303L33.0496 0.913564C33.0496 0.913564 31.9261 0.928303 31.3939 0.928303C30.8173 0.928303 29.7382 0.913564 29.7382 0.913564L29.7086 0.928303V1.28204C29.7086 1.35574 29.7677 1.39995 29.8417 1.39995H30.0486C30.6252 1.39995 30.8617 1.57682 30.8617 2.04847Z"
                fill="white"
              />
              <path
                d="M40.2069 2.15165C40.2069 1.72421 40.2808 1.51787 41.2417 1.51787C41.9217 1.51787 42.8974 1.78317 42.8974 3.2276C42.8974 4.40673 42.0843 4.89312 41.0347 4.89312H40.2069V2.15165ZM40.2069 5.51216H41.2565C42.7791 5.51216 43.5922 6.41124 43.5922 7.6788C43.5922 8.56315 43.1339 9.84545 41.1825 9.84545C40.2956 9.84545 40.2069 9.62436 40.2069 9.19693V5.51216ZM37.265 10.1255V10.4203L37.2798 10.435H41.4634C44.494 10.435 45.4697 9.12323 45.4697 7.84093C45.4697 6.54389 44.8192 5.55638 43.2522 5.14368V5.1142C44.0505 4.76046 44.6714 3.94981 44.6714 3.19812C44.6714 2.18112 44.1096 0.898824 41.1382 0.898824C40.399 0.898824 39.6599 0.928303 39.2164 0.928303C38.7729 0.928303 37.2946 0.898824 37.2946 0.898824L37.265 0.928303V1.25256C37.265 1.35574 37.3094 1.39995 37.472 1.39995H37.9598C38.3146 1.39995 38.4033 1.56208 38.4033 2.00426V9.34432C38.4033 9.81597 38.2998 9.94862 37.9598 9.94862H37.5311C37.3093 9.94862 37.265 10.0223 37.265 10.1255Z"
                fill="white"
              />
              <path
                d="M49.9063 10.435H57.1205C57.224 9.6391 57.3718 8.72528 57.5197 7.94411L56.884 7.79672C56.6327 8.88741 56.1005 9.75701 55.2874 9.75701H53.0256C52.8925 9.75701 52.8186 9.66858 52.8186 9.32958V5.67429H54.4891C54.8587 5.67429 54.9178 6.11646 55.0065 6.72076C55.0065 6.77972 55.0952 6.86815 55.2431 6.86815H55.5535L55.6422 6.7355C55.6126 6.48494 55.5387 5.89537 55.5387 5.36477C55.5387 4.81942 55.6126 4.22986 55.6422 3.99403L55.5535 3.84664H55.2431C55.0952 3.84664 55.0065 3.94981 55.0065 4.00877C54.9178 4.59833 54.8587 5.05525 54.4891 5.05525H52.8186V2.01899C52.8186 1.70947 52.8925 1.59156 53.0256 1.59156H54.9474C55.9379 1.59156 56.3074 2.68225 56.5144 3.43395L57.1057 3.3013C56.9579 2.5496 56.8544 1.76843 56.7953 0.913564H49.9063L49.862 0.943041V1.28204C49.862 1.35574 49.9211 1.39995 49.9803 1.39995H50.1872C50.7785 1.39995 51.0003 1.57682 51.0003 2.04847V9.3001C51.0003 9.77175 50.7785 9.94862 50.1872 9.94862H49.9803C49.9211 9.94862 49.862 9.99284 49.862 10.0813V10.4055L49.9063 10.435Z"
                fill="white"
              />
            </svg>
            <sup>®</sup>
          </p>
        </a>
      </div>
    </div>
  );
};
